<template>
  <div @click.stop="setSeen" :class="{ new: !seen }" v-if="!seen">
    <img src="@/assets/icons/bell.svg" />
    <h1>{{ name }}</h1>
    <h2>{{ text }}</h2>
    <h3>{{ this.$formatter.formatTimeSince(notification.date) }}</h3>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "Notification",
  props: {
    notification: Object,
  },
  methods: {
    async setSeen() {
      this.$emit("refresh");
      this.redirect(this.redirectTo);
      let response = await this.$api.notification.setSeen(this.notification.id);
      if (response.success === true) {
        this.notification.seen = true;
      }
    },
    redirect(path) {
      if (path !== false) {
        this.$router.push({ path: path });
      }
    },
  },
  computed: {
    ...mapStores(useUserStore),
    text() {
      switch (this.notification.data.type) {
        case "comment": {
          return "commented on your post.";
        }
        case "follower": {
          return "started following you.";
        }
        case "group_post": {
          return "New group post.";
        }
        case "group_comment": {
          return "New comment on group post.";
        }
        default: {
          return this.notification.text;
        }
      }
    },
    name() {
      return this.notification.data.username !== undefined
        ? this.notification.data.username
        : "";
    },
    redirectTo() {
      switch (this.notification.data.type) {
        case "comment": {
          return "/post/" + encodeURIComponent(this.notification.data.id_post);
        }
        case "follower": {
          return `/user/${encodeURIComponent(this.notification.data.username)}-${this.notification.data.hash_email}`;
        }
        case "event": {
          return (
            "/group/" +
            encodeURIComponent(this.notification.data.id_group) +
            "/tab/" +
            encodeURIComponent(this.notification.data.id_tab)
          );
        }
        case "group_post": {
          return "/post/" + encodeURIComponent(this.notification.data.id_post);
        }
        case "group_comment": {
          return (
            "/group/" +
            encodeURIComponent(this.notification.data.id_group) +
            "/tab/" +
            encodeURIComponent(this.notification.data.id_tab)
          );
        }
        default: {
          return false;
        }
      }
    },
    seen() {
      return this.notification.seen === 1;
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  @extend .pointer;
  position: relative;
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-rows: auto auto auto;
  gap: 0px 0px;
  grid-template-areas:
    "image name"
    "image text"
    "image date";

  padding: 3px 10px;
  margin: 5px 5px;
  border-radius: 10px;

  h1,
  h2,
  h3 {
    text-align: left;
    margin: 0;
  }
  img {
    margin-top: calc(50% - 17px);
    grid-area: image;
    width: 30px;
    height: 30px;
  }
  h1 {
    grid-area: name;
    font-size: 1em;
    color: var(--text-2);
  }
  h2 {
    grid-area: text;
    font-size: 0.7em;
    color: var(--text-2-light);
  }
  h3 {
    grid-area: date;
    font-size: 0.7em;
    color: var(--dark);
  }
}
.new {
  color: var(--dark);
  background-color: var(--orange);
}
</style>
