<template>
  <div class="container" @click="toggle">
    <div v-if="show" @click.stop="toggle" class="outside"></div>
    <!-- Captures any clicks outside the box -->

    <img src="@/assets/icons/settings.svg" />

    <div v-if="show" class="box">
      <div id="arrow"></div>

      <!-- <DarkModeButton /> -->
      <button v-if="userStore.isAdmin" @click.stop="redirect('/admin')">Admin</button>
      <button v-if="userStore.isAdmin" @click.stop="redirect('/LoginStats')">
        eFAN Statistics
      </button>
      <button @click.stop="openTermsAndConditions">Terms & Conditions</button>
      <button @click.stop="logout">Log out</button>
    </div>
  </div>
</template>

<script>
// Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import DarkModeButton from "@/components/DarkModeButton"

export default {
  name: "SettingsButton",
  data() {
    return {
      show: false,
    }
  },
  components: { DarkModeButton },
  methods: {
    openTermsAndConditions() {
      this.$router.push({ path: "/terms-and-conditions"});
    },
    toggle(e) {
      this.show = !this.show
    },
    async logout() {
      await this.$api.logout(this.userStore.accessToken)
        .then(response => {
          if(response && response.error) {
            return;
          } 

          this.userStore.$reset();
          this.$store.cleanUserSubscriptions();
          this.$router.push({ path: "/landing" });
        });

    },
    redirect(path) {
      this.$router.push({ path })
    },
  },
  computed: {
    ...mapStores(useUserStore),
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  padding: 10px;

  .outside {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
  }

  img {
    @extend .pointer;
    @extend .noselect;
    @extend .nodrag;
    width: 100%;
    height: 100%;
  }
  .box {
    @include box-shadow;
    padding: 10px;
    position: absolute;

    margin-left: -155px;
    top: 50px;
    width: 200px;

    z-index: 99;
    background-color: var(--dark);
    border-radius: 10px;

    button {
      @include button;
      width: 90%;
    }
    #arrow {
      position: absolute;
      top: -20px;
      left: 160px;
      width: 0;
      height: 0;

      // Make a triangle
      border: 10px solid transparent;
      border-bottom: 10px solid var(--dark);
    }
  }
}
</style>
