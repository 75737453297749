<template>
	<div class="autocomplete">
		<form @submit.prevent>
			<input
				type="search"
				inputmode="search"
				maxlength="20"
				v-model="search"
				@input="onChange"
				@keydown.down="onArrowDown"
				@keydown.up="onArrowUp"
				@keydown.enter="onEnter"
				placeholder="Search for tags..."
				autocomplete="on" />
		</form>
		<!-- Show results -->
		<ul	
			ref="list"
			v-show="isOpen"
			class="autocomplete-results">
			<li
				v-if="isAsync && isLoading"
				class="loading"
			>
				<!-- TODO: Create a spinner if async -->
				Loading results...
			</li>
			<li	
				v-if="!isLoading && results.length > 0"
				v-for="(result, i) in results"
				@click="onResultClickHandler(i)"
        		:key="i"
				class="autocomplete-result"
				:class="{ 'is-active': i === arrowCounter }">
				<span>{{ result }}</span>
			</li>

			<li 
				v-if="!isLoading && results.length === 0 && this.search.length <= 20"
				class="autocomplete-result-empty">
				<span>No tag found.</span>
				<span>Searched for <span class="search-input">{{ search }}</span></span>
			</li>
			<li 
				v-if="this.search.length == 20"
				class="autocomplete-result-empty"
			>
				<span>{{ errors.search }}</span>
			</li>
    	</ul>
	</div>
</template>

<script>
	// TODO: add a x button to the input in mozila browser
	export default {
		name: "SearchBar",
		props: {
			data: {
				type: [],
				required: true,
				default: () => []
			},
			isAsync: {
				type: Boolean,
				required: false,
				default: false
			},
		},
		mounted() {
    		document.addEventListener('click', this.handleClickOutside);
  		},
		destroyed() {
			document.removeEventListener('click', this.handleClickOutside);
  		},
		data() {
			return {
				search: '',
				results: [],
				isOpen: false,
				isLoading: false,
				arrowCounter: -1,
				errors: {
					search: ''
				}
			};
		},
		methods: {
			setResult(result) {
				this.search = result;
				this.isOpen = false;
			},
			filterResults() {
      			this.results = this.data.filter(item => item.name.toLowerCase().trim()
					.indexOf(this.search.toLowerCase().trim()) > -1)
					.map(item => item.name);
    		},
			onChange() {
				this.$emit('input', this.search);

				if(this.isAsync) {
					this.isLoading = true;
				} else {
					this.validateInput();
					this.filterResults();
					this.isOpen = true;
				}
			},
			handleClickOutside(event) {
				if (!this.$el.contains(event.target)) {
					this.arrowCounter = -1;
					this.isOpen = false;
				}
    		},
			onArrowDown() {
				if(this.isOpen) {
					const ul = this.$refs.list;
					const lastLi = ul.lastElementChild;

					if (this.arrowCounter < this.results.length - 1) {
						if(this.arrowCounter > 0 && !this.isLastElementVisible(ul, lastLi)) {
							ul.scrollTop += lastLi.offsetHeight;
						}

						this.arrowCounter = this.arrowCounter + 1;
					} else {
						this.arrowCounter = 0;
						ul.scrollTop = 0;
					}
				}
			},
			onArrowUp() {
				if(this.isOpen) {
					const ul = this.$refs.list;
					const firstLi = ul.firstElementChild;

					if (this.arrowCounter >= 1) {
						if(!this.isFirstElementVisible(ul, firstLi)) {
							ul.scrollTop -= firstLi.offsetHeight;
						}
						this.arrowCounter = this.arrowCounter - 1;
					} else {
						this.arrowCounter = this.results.length - 1;
						ul.scrollTop = ul.scrollHeight;
					}
				}
			},
			onEnter() {
				this.selectResult();
			},
			onResultClickHandler(index) {
				this.selectResult(index);
			},
			selectResult(index = -1) {
				if(index === -1 && this.arrowCounter === -1)
					return;

				this.search = this.results[index >= 0 ? index : this.arrowCounter];

				if(this.search) {
					this.arrowCounter = -1;
					this.isOpen = false;
					this.$router.push({ path: `/tag/${this.search}`});
				}
			},
			validateInput() {
				this.errors.search = this.search.length >= 20 ? 
					'The length of a tag name must be 20 characters or fewer.':
					'';
			},
			isFirstElementVisible(container, element) {
				const containerRect = container.getBoundingClientRect();
				const elementRect = element.getBoundingClientRect();
				
				return (
					elementRect.top >= containerRect.top && 
					elementRect.bottom <= containerRect.bottom
				);
			},
			isLastElementVisible(container, element) {
				const containerRect = container.getBoundingClientRect();
      			const elementRect = element.getBoundingClientRect();

				return (
					elementRect.bottom <= containerRect.bottom && 
					elementRect.top >= containerRect.top
				);
			}
		},
		watch: {
			items: function(value, oldValue) {
				if(this.isAsync) {
					this.results = value;
					this.isOpen = true;
					this.isLoading = false;
				}
			}
		},
		computed: {
			isValid() {
				return !Object.values(this.errors).some(val => val !== '')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.autocomplete {
		position: relative;
		display: flex;
		flex-direction: column;

		&-results {
			position: absolute;
			top: 85%;
			left: 0;
			width: 100%;
			max-width: 300px;
			height: auto;
			min-height: 1em;
			max-height: 15em;
			z-index: 10;    

			padding: 0;
			margin: 0;
			background-color: var(--dark);
			border: 1px solid #4b4b4b;
			border-radius: 5px;
			@include scrollbars;
			overflow: auto;
		}

		&-result {
			color: #fff;
			background-color: inherit;
			list-style: none;
			text-align: left;
			padding: 10px;
			
			cursor: pointer;
			&.is-active,
			&:hover {
				background-color: var(--orange);
				color: white;
			}
		}

		&-result-empty {
			display: flex;
			flex-direction: column;

			color: #fff;
			background-color: inherit;
			list-style: none;
			text-align: left;
			padding: 10px;
			@extend .nodrag;
			@extend .noselect;
			cursor: default;
			
			.search-input {
				color: var(--orange);
			}
		}
	}

	form {
		display: flex;
		padding: 0;
		border: none;
		border-radius: 15px;
		white-space: nowrap;
		width: 100%;

		input[type="search"] {
			width: inherit;
			max-width: 300px;
			color: var(--text);

			margin: 0;
			font-size: 14px;
			border: 1px solid transparent;
			border-radius: inherit;
			border: 1px solid #555;
			padding: 9px 4px 9px 40px;
			background: var(--dark) url(../assets/icons/search.svg) no-repeat 13px center;

			&::-webkit-search-cancel-button {
				-webkit-appearance: none;
				cursor: pointer;

				height: 20px;
				width: 20px;
				border-radius:10px;
				background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
			}
		}

		input[type="search"]::placeholder {
			color: #bbb;
		}

		button[type="submit"]:focus,
		input[type="search"]:focus {
			box-shadow: 0 0 3px 0 var(--light);
			border-color: var(--light);
			outline: none;
		}
	}
</style>