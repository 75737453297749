<template>
	<div>
		<button @click.stop="toggle">{{ text }}</button>
	</div>
</template>

<script>
	export default {
		name: "DarkModeButton",
		data() {
			return {
				currentTheme: this.$store.getColorMode()
			};
		},
		methods: {
			toggle() {
				let app = document.querySelector("#app.app");

				app.classList.remove(this.currentTheme);
				this.currentTheme = (this.currentTheme === "light") ? "dark" : "light";
				app.classList.add(this.currentTheme);

				this.$store.setColorMode(this.currentTheme);
			}
		},
		computed: {
			text() {
				return (this.currentTheme === "light") ? "Turn on dark mode" : "Turn on light mode"
			}
		}
	}
</script>

<style lang="scss" scoped>
	div {
		width: 90%;

		button {
			@include button;
			width: 100%;
		}
	}
</style>