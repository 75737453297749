<template>
  <div class="container" @click="toggle">
    <div v-if="show" @click.stop="toggle" class="outside"></div>
    <!-- Captures any clicks outside the box -->

    <div class="button">
      <span v-if="unreadCount > 0 || unreadCount === '9+'" id="unreadCount">{{
        unreadCount
      }}</span>
      <!-- Unread notifications number -->
      <img src="@/assets/icons/notifications.svg" />
    </div>

    <div v-if="show" class="box">
      <div id="arrow"></div>

      <Notification
        v-for="notification in top"
        :key="notification.id"
        :notification="notification"
        @refresh="refresh"
      />

      <h3 v-if="notifications.length === 0">No new notifications</h3>
      <button @click="onOpenNotificationsView" class="archive-btn">
        <img src="@/assets/icons/archive-icon.svg" />
        <h1>Notification archive</h1>
      </button>
    </div>
  </div>
</template>

<script>
import Notification from "@/components/notification/Notification";
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "NotificationButton",
  components: { Notification },
  data() {
    return {
      show: false,
      notifications: [],
    };
  },
  async mounted() {
    this.getData();
  },
  methods: {
    onOpenNotificationsView() {
      this.$router.push({ path: "/notifications" })
    },
    toggle() {
      if (!this.show) {
        this.getData()
      };
      this.show = !this.show;
    },
    // Get notifications for a user
    async getData() {
      const response = await this.$api.notification.get(this.userStore.user.id);
      if (response.success === true) {
        this.notifications = response.notifications;
        this.notifications.forEach((x) => {
          if (typeof x.data !== "object") x.data = JSON.parse(x.data);
        });
      }
    },

    refresh() {
      this.getData();
    },
  },
  watch: {
    show(value) {
      if(!value && this.notifications.length > 0) {
        this.$api.cleanUserNotifications(this.userStore.accessToken)
        this.notifications = [];
      }
    }
  },
  computed: {
    ...mapStores(useUserStore),
    top() {
      let n = 5;
      return this.notifications.reverse().slice(0, n);
    },
    unreadCount() {
      return this.notifications.length < 10 ? this.notifications.length : "9+";
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  padding: 10px;

  .outside {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
  }

  .button {
    @extend .pointer;
    @extend .noselect;
    @extend .nodrag;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }
    #unreadCount {
      position: absolute;
      top: -8px;
      left: -3px;

      color: var(--dark);
      background-color: var(--text);
      font-weight: bold;
      border-radius: 50%;

      padding: {
        right: 0.3em;
        left: 0.3em;
        top: 0.1em;
        bottom: 0.1em;
      }
    }
  }

  .box {
    @include box-shadow;
    padding: 5px;
    position: absolute;
    z-index: 99999;

    margin-left: -155px;
    top: 50px;
    width: 200px;

    background-color: var(--dark);
    border-radius: 10px;
    text-align: center;
    
    h3 {
      padding: 5px;
      color: var(--text);
    }
    #arrow {
      position: absolute;
      top: -20px;
      left: 160px;
      width: 0;
      height: 0;

      // Make a triangle
      border: 10px solid transparent;
      border-bottom: 10px solid var(--dark);
    }

    .archive-btn {
      @include button(10px, false, var(--medium));
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      width: 190px;
      color: var(--text);

      img {
        width: 20px;
      }

      h1 {
        font-family: "Roboto", sans-serif;
        margin: 0;
        font-size: 1em;
        color: var(--text);

      }
    }
  }
}
</style>
