<template>
  <nav>
    <div class="grid-container">
      <div class="logo" @click="onLogoClick()">
        <img class="logo" src="@/assets/img/logo-efan/logo_efan_072_1280px.png" />
      </div>

      <SearchBar :data="tags" class="search" />

      <div class="menu">
        <img
          src="@/assets/icons/groups.svg"
          title="Groups"
          @click="redirect('/groups')"
        />
        <div class="tag-menu">
          <img
              src="@/assets/icons/tags.svg"
              title="Tags"
              class="tag-menu--image"
              @click="redirect('/tags')"
            />
          <div v-if="tagNameCache" @click="redirect('/tags')" class="tag-menu--x"></div>
        </div>
        <img
          src="@/assets/icons/group.svg"
          title="Friends"
          @click="redirect('/friends')"
        />
        <img
          src="@/assets/icons/calendar.svg"
          title="Calendar"
          @click="redirect('/calendar')"
        />
      </div>

      <div class="profile">
        <img
          class="profileImg"
          :src="profileImg"
          title="My profile"
          @click="redirect(profileLink)"
        />
        <h3 @click="redirect(profileLink)">
          {{ userStore.user ? userStore.user.username : 'username' }}
        </h3>
      </div>

      <div class="settings">
        <NotificationButton />
        <SettingsButton />
      </div>
    </div>
  </nav>
</template>

<script>
// Stores
import { mapState, mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
import { useTagStore } from "@/stores/IsolatedTagStore";
// Components
import SearchBar from "@/components/SearchBar"
import SettingsButton from "@/components/SettingsButton"
import NotificationButton from "@/components/notification/NotificationButton"

export default {
  name: "Navbar",
  components: { SearchBar, NotificationButton, SettingsButton },
  data() {
    return {
      tags: []
    }
  },
  mounted() {
    this.fetchTags();
  },
  methods: {
    onLogoClick() {
      this.$emit('refreshFeed');
      this.redirect('/');
    },
    async fetchTags() {
      const response = await this.$api.getTags();
      if(response.success) {
        this.tags = response.tags
      }
    },
    redirect(path) {
      document.querySelector('body').style.overflow = 'auto';
      if(path === '/tags') {
        if(this.tagNameCache === null) {
          this.$router.push({ path: path })
        } else {
          this.$router.push({ path: '/' })
        }
      } else {
        this.$router.push({ path: path })
      }
    },
  },
  computed: {
    ...mapStores(useUserStore),
    profileLink() {
      if(this.userStore.user) {
        return `/user/${this.userStore.user.username}-${this.userStore.user.hash_email}`
      } else {
        return ''
      }
    },
    profileImg() {
      let img = this.userStore.user ? this.userStore.user.img_url : null;
      return this.$formatter.formatProfileImg(img);
    },
    ...mapState(useTagStore, ['tagNameCache'])
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  position: sticky;
  z-index: 1000;
  @include respond-to("small") {
    max-width: 100vw;
  }
}

.menu img[title="Streams"],
.menu img[title="Notifications"],
.menu img[title="Chat"],
.search {
  @include respond-to("small") {
    display: none !important;
  }
}

.grid-container {
  @include gradient;

  display: grid;
  grid-template-columns: 0.5fr 1.2fr 1.6fr 1.1fr 0.6fr;
  grid-template-rows: auto;
  gap: 0px 0px;
  grid-template-areas: "logo search menu profile settings";
  padding: 0px;

  @include respond-to("medium") {
    grid-template-columns: 0.9fr 1.2fr 0.9fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "profile logo search"
      "menu menu settings";

    .profile {
      margin-left: 10px;
      .profileImg {
        top: 0 !important;
        width: 50px !important;
        height: 50px !important;
      }
    }
  }

  @include respond-to("small") {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "profile logo"
      "menu settings";

    .profile {
      margin-left: 10px;
      .profileImg {
        top: 0 !important;
        width: 50px !important;
        height: 50px !important;
      }
    }
  }

  .logo {
    @extend .nodrag;
    @extend .noselect;
    @extend .pointer;
    @include white-filter;

    grid-area: logo;
    margin-left: 40px;
    width: 140px;
    height: auto;

    @include respond-to("medium") {
      margin-left: 0;
    }

    @include respond-to("small") {
      width: 120px;
      margin-top: 4px;
    }
  }

  .search {
    grid-area: search;
    margin-left: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to("medium") {
      margin-right: 10px;
    }
  }

  .menu {
    grid-area: menu;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;

    .tag-menu {
      position: relative;

      &--x {
        position: absolute;
        top: 6px;
        left: 4px;

        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: var(--text);
        cursor: pointer;

        background-image: url('../assets/icons/x-black.svg');
        @include set-element-background-image();

        &:hover {
          @include popup;
        }
      }
    }

    @include respond-to("small") {
      max-width: 90%;
    }

    @include respond-to("medium") {
      justify-content: inherit;
    }

    img {
      @extend .nodrag;
      @extend .noselect;
      @extend .pointer;
      @include white-filter;

      height: 40px;
      padding: 10px;

      &:hover {
        @include popup;
      }
    }
  }

  .profile {
    grid-area: profile;

    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to("medium") {
      justify-content: start;
    }

    .profileImg {
      @extend .pointer;
      @extend .nodrag;
      @extend .noselect;
      @include profile-img(50px);

      // position: relative;
      // top: 15px;
    }
    h3 {
      @extend .pointer;

      color: var(--text-light);
      padding: 0 10px 0 10px;
    }
  }

  .settings {
    grid-area: settings;
    padding-right: 20px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    @include respond-to("medium") {
      padding-right: 0;
      justify-content: flex-end;
      margin-right: 10px;
    }

    * {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
